import { isAPP, getPlatform } from "@environment/type";

export function getVersionNumber() {
    if (isAPP() && getPlatform() == 'ios') {
        return new Promise((resolve) => {
            cordova.getAppVersion.getVersionNumber().then((version) => {
                resolve(version)
            });
        });
    }
}


export default {
    getVersionNumber
}